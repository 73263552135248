.text-welcome-main {
  border: 0px solid red;
  width: 100%;
}
.text-welcome-title {
  border: 0px solid red;
  width: 450px;
  background-color: #66614f;
  margin: 0px 0px 0px 240px;
  color: #fef6e8;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
  padding: 3px 20px;
  font-size: 13px;
}
.text-welcome-description {
  border: 0px solid black;
  margin: 0px 0px 0px 260px;
  color: #002d2e;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
  font-size: 12px;
  width: 40%;
  padding: 0px;
}
.text-welcome,
.text-welcome-title {
  display: inline-block;
}
.text-welcome {
  border: 0px solid white;
  position: absolute;
  padding: 0;
  margin: 0;
  color: #fef6e8;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
  padding: 4px 30px;
  font-size: 13px;
}
