.nav-large {
  border-bottom: 3px solid #66614f;
  /*background-color: rgba(3, 3, 3, 0.2) !important;*/
  z-index: 99;
  position: absolute !important;
  width: 100%;
  padding: 0 !important;
}
.navbar-large {
  background-color: rgba(102, 97, 79, 0.7);
  min-height: 100px;
  flex-direction: column;
}

.home-date {
  border: 0px solid red;
  position: relative;
  width: 100%;
  height: 30px;
  margin-bottom: 8px;
}
.home-date .date-uno {
  border: 0px solid red;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 8px;
}
.home-date .date-uno p {
  color: #fef6e8;
  margin: 10px 0px 0px 15px;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.3em;
}

.home-date .date-dos {
  border: 0px solid white;
  position: absolute;
  top: 0;
  right: 0;
}
.home-date .date-dos p {
  color: #fef6e8;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 8px;
  font-weight: bold;
  letter-spacing: 0.3em;
  margin: 10px 15px 0px 0px;
}
.home-elisa p {
  color: #fef6e8;
  border: 0px solid red;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
}
.navbar-large ul {
  border: 0px solid red;
  margin: 0;
  padding: 0;
}
.navbar-large ul li {
  border: 0px solid black;
  display: inline-block;
  padding: 0;
  margin: 8px 30px;
}

.navbar-large ul li a {
  border: 0px solid white;
  padding: 0px 0px 0px 3px;
  font-family: "Montserrat", sans-serif;
  font-size: 9px;
  letter-spacing: 0.4em;
  color: #fef6e8;
}
.navbar-large ul li a:hover {
  font-weight: bolder;
  border-bottom: 1px solid #fef6e8;
  transition: all 5ms;
}

.home-img {
  border: 0px solid red;
  margin-top: -10px;
}

/*small */
.nav-small {
  border-bottom: 3px solid #66614f;
  position: absolute;
  z-index: 99;
  width: 100%;
  background-color: rgba(102, 97, 79, 0.7);
}
.nav-small button {
  border: 0px solid red;
  padding: 4px;
}
.nav-small button .nav-uno {
  border: 2px solid white;
  width: 40px;
}
.nav-small button .nav-dos {
  border: 2px solid white;
  width: 40px;
  margin: 8px 0px;
}
.nav-small button .nav-tres {
  border: 2px solid white;
  width: 40px;
}
.navbar-small {
  border: 0px solid white;
}
.navbar-small ul {
  border: 0px solid white;
}
.navbar-small ul li {
  border: 0px solid white;
}
.navbar-small ul li a {
  border: 0px solid white;
  font-family: "Montserrat", sans-serif;
  font-size: 9px;
  letter-spacing: 0.4em;
  color: #fef6e8 !important;
}
.navbar-small ul li a:hover {
  font-weight: bolder;
  transition: all 5ms;
  color: #fef6e8 !important;
}
.img-monograma img {
  border: 0px solid white;
  width: 80px;
}
.brand-elisa {
  color: #fef6e8;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
  font-size: 13px;
}
.brand-elisa:hover{
  text-decoration: none;
  color: #fef6e8;
}

.nav-borders {
  border-bottom: 1px solid white !important;
}
