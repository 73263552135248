.home-background-uno {
  background-image: url("../../images/home-back-dos.png");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  -webkit-background-position: center;
  -moz-background-position: center;
  -o-background-position: center;
  height: 100vh;
  border: 0px solid red;
  position: relative;
}

.home-elisa-daniel-large{
  border:0px solid red;
  height: 100vh;
  top: 50%;
}
.home-elisa-daniel-large img{
  border:0px solid red;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-elisa-daniel{
  border:0px solid red;
  height: 100vh;
  top: 50%;
}
.home-elisa-daniel img{
  border:0px solid red;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}