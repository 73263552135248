.travel {
  border: 0px solid black;
  position: absolute;
  margin-top: 130px;
  width: 100%;
}
.travel-aside {
  background-image: url("../../images/travel-barra.png");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  -webkit-background-position: center;
  -moz-background-position: center;
  -o-background-position: center;
  border: 0px solid red;
  position: absolute;
  height: 130vh;
  width: 250px;
}
.travel-plant-right {
  border: 0px solid black;
  position: absolute;
  height: 130vh;
  width: 100%;
  right: 0;
  top: 0;
  background-image: url("../../images/travel-plant.png");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  -webkit-background-position: center;
  -moz-background-position: center;
  -o-background-position: center;
}
.travel-text {
  position: absolute;
}
.text-description-travel{
  border: 0px solid black;
  margin: 0px 0px 0px 260px;
  color: #002d2e;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
  font-size: 12px;
  width: 40%;
  padding: 1px 5px;
}