.main-mobile-description {
  border: 0px solid red;
  margin: 130px 0px 0px 0px;
  padding: 0;
}

.description-mobile-title {
  border: 0px solid red;
  width: 300px;
  background-color: #66614f;
  margin: 0px 0px 0px 0px;
  color: #fef6e8;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
  padding: 3px 20px;
  font-size: 13px;
}

.description-text-title {
  border: 0px solid red;
  width: 450px;
  background-color: #66614f;
  margin: 0px 0px 0px 240px;
  color: #fef6e8;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
  padding: 3px 20px;
  font-size: 13px;
}

.main-button-mobile-zola {
  border: 0px solid red;
  width: 300px;
  margin: 20px 0px 0px 10px;
  border-radius: 5px;
  padding: 3px;
  background-color: #778573;
}

.main-button-mobile-zola a {
  border: 1px solid #fef6e8;
  height: 100%;
  display: block;
  border-radius: 4px;
  text-align: center;
  color: #fef6e8;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
  padding: 5px;
}
.main-button-mobile-zola a:hover{
  text-decoration: none;
  font-style: italic;
}

.main-button-zola {
  border: 0px solid red;
  width: 300px;
  margin: 20px 0px 0px 260px;
  border-radius: 5px;
  padding: 3px;
  background-color: #778573;
}
.main-button-zola a {
  border: 1px solid #fef6e8;
  height: 100%;
  display: block;
  border-radius: 4px;
  text-align: center;
  color: #fef6e8;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
  padding: 5px;
}
.main-button-zola a:hover{
  text-decoration: none;
  font-style: italic;
}
