.things-aside {
  background-image: url("../../images/things-barra.png");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  -webkit-background-position: center;
  -moz-background-position: center;
  -o-background-position: center;
  border: 0px solid red;
  position: absolute;
  height: 130vh;
  width: 250px;
}
.things-plant-right {
  border: 0px solid black;
  position: absolute;
  height: 130vh;
  width: 100%;
  right: 0;
  top: 0;
  background-image: url("../../images/things-plant.png");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  -webkit-background-position: center;
  -moz-background-position: center;
  -o-background-position: center;
}
.things-text{
  position: absolute;
}
.mobile-text{
  border:1px solid red;
}
