.text-main {
  border: 0px solid red;
  width: 100%;
}
.text-title {
  border: 0px solid red;
  width: 300px;
  background-color: #66614f;
  margin: 0px 0px 0px 240px;
  color: #fef6e8;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
  padding: 3px 20px;
  font-size: 13px;
}
.text-description {
  border: 0px solid black;
  margin: 0px 0px 0px 260px;
  color: #002d2e;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
  font-size: 12px;
  width: 40%;
  padding: 5px;
}
.text-description a {
  color: #002d2e;
  text-decoration: none;
  font-style: italic;
}
.text-description a:hover {
  color: #002d2e;
  text-decoration: underline;
  font-style: italic;
}

.text-descriptionDos {
  border: 0px solid black;
  margin: 0px 0px 0px 260px;
  color: #002d2e;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
  font-size: 12px;
  width: 55%;
  padding: 5px;
}
/*******************************/
.text-mobile-main {
  border: 0px solid red;
  margin: 130px 0px 0px 0px;
  padding: 0;
}
.text-mobile-title {
  border: 0px solid red;
  width: 250px;
  background-color: #66614f;
  margin: 0px 0px 0px 0px;
  color: #fef6e8;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
  padding: 3px 20px;
  font-size: 13px;
}
.text-mobile-description {
  border: 0px solid black;
  margin: 0px 0px 0px 0px;
  color: #002d2e;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
  font-size: 12px;
  padding: 8px;
}
.text-mobile-description a {
  color: #002d2e;
  text-decoration: underline;
  font-style: italic;
}
.text-mobile-descriptionDos {
  border: 0px solid black;
  margin: 0px 0px 0px 0px;
  color: #002d2e;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
  font-size: 12px;
  padding: 8px;
}
.mobile-dos {
  border: 0px solid red;
  position: absolute;
}

.anchor-description {
  color: #002d2e;
  font-style: italic;
  text-decoration: underline;
}
.anchor-description:hover {
  color: #002d2e;
}
.text-mobile-shedule-description {
  border: 0px solid black;
  margin: 0px 0px 0px 0px;
  color: #002d2e;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
  font-size: 12px;
  padding: 1px 8px;
}

.text-mobile-shedule-description a{
  color: #002d2e;
  text-decoration: underline;
  font-style: italic;
}
.text-description-shedule {
  border: 0px solid black;
  margin: 0px 0px 0px 260px;
  color: #002d2e;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
  font-size: 12px;
  width: 40%;
  padding: 1px 5px;
}
.text-description-shedule a{
  color: #002d2e;
  text-decoration: underline;
  font-style: italic;
}